
import { defineComponent } from 'vue';
import Utilities from '@utilities/Utilities';
import DashboardInteractor from "@interactors/dashboard/Dashboard.interactor";
import Container from 'typedi';

export default defineComponent({
    props: ['index', 'options', 'updateOptions', 'chartType'],
    name: "DashboardOptionsSizesDropdown",
    data() {
        return {
            chartOptions: Container.get(DashboardInteractor).getChartOptions(this.chartType),
            isLegendOn: this.options.options.isChartLegendEnabled,
            typesID: Utilities.getIdGenerator().generateV4(),
            sizesID: Utilities.getIdGenerator().generateV4(),
            type: this.options.type,
        };
    },
    watch: {
        chartCategory() {
            this.chartOptions = Container.get(DashboardInteractor).getChartOptions(this.chartType);
            return;
        },
    },
    methods: {
        getSpacedString(string: string) {
            return string.replace(/[A-Z]/g, ' $&').trim();
        },
        handleSizeChange(size: string) {
            this.updateOptions(this.index, size, 'chartSize');
        },
        handleTypeChange(type: string) {
            this.updateOptions(this.index, type, '', true);
        },
        handleMouseEnter(elID: string) {
            const el: HTMLElement | null = document.getElementById(elID);
            if (el !== null) {
                el.style.display = 'block';
                return;
            }
            return;
        },
        handleMouseLeave(elID: string) {
            const el: HTMLElement | null = document.getElementById(elID);
            if (el !== null) {
                el.style.display = 'none';
                return;
            }
            return;
        },
        handleLegendToggle() {
            this.updateOptions(this.index, this.isLegendOn, 'isChartLegendEnabled');
            return;
        },
        isEnabledSize(size: string) {
            if (this.options.options.chartSize === size) {
                return true;
            }

            return false;
        },
        isEnabledType(type: string) {
            if (this.chartType == type) {
                return true;
            }

            return false;
        },
    },
});
